















import Component from 'vue-class-component';

import { BaseVue } from '../../BaseVue';
import Users from '../../components/org/UserList.vue';
@Component({
  components: {
    Users,
  },
})
export default class UsersClass extends BaseVue {
  public selectedTab = 0;
}
